
import { defineComponent, ref, watch, onMounted, Ref } from "vue";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Api from "@/services/Api";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import { useTicketStore } from "@/store/TicketStore";
import useAlert from "@/composables/Alert";
import { useRoute } from "vue-router";

interface AttendanceFilter {
  codigo: string
  title: string; //resumo
  status: string;
  store: string;
  category: string;
  solicitante: any;
  support: string;
  date: string,
}



export default defineComponent({
  name: "FilterAttendance",
  components: {
    FilterBase,
    ElConfigProvider,
  },

  setup(props, { emit }) {
    const route = useRoute();
    const storeFilter = useTicketStore();
    const arrayStatus: Ref<any> = ref([]);
    const arrayUsers: Ref<any> = ref([]);
    const closeModal = ref(false);
    const lojas: Ref<any[]> = ref([]);
    const arrayCategory: Ref<any> = ref([]);
    const selectedConcessionarias: Ref<any> = ref([]);
    const { showTimeAlert } = useAlert();
    const id_project: Ref<any> = ref(localStorage.getItem('id_project')); //numero do projeto

    const attendanceFilter: Ref<AttendanceFilter> = ref({
      codigo: "",
      title: "",
      status: "",
      store: "",
      category: "",
      solicitante: "",
      support: "",
      date: "",
    });

    async function getLojas() {
      try {
        const object:{
          project: number | null
        } = {
          project: null,
        }

        switch(+id_project.value) {
          case 1:
            object.project = 1
            break;
          case 3:
            object.project = 3
            break;
        }

        const { data } = await Api.get("getPdvs", { ...object });
        lojas.value = data.response.data
      }catch(error){
        console.log('Falha ao buscar lojas', error);
      }
    }

    async function getStatus() {
      const { data } = await Api.get("status")

      arrayStatus.value = data
    }
    async function getCategories(){
      const { data } = await  Api.get('categories');
      arrayCategory.value = data
    }

    async function getUsers(){
      const { data } = await Api.get('users');
      arrayUsers.value = data
    }

    function sendFilter() {
      attendanceFilter.value.codigo = attendanceFilter.value.codigo.replace(/\D/g, '');
      storeFilter.sendFilter(attendanceFilter.value)      
      closeModal.value = !closeModal.value;
    }

    onMounted(() => {
      getLojas()
      getStatus()
      getUsers()
      getCategories()
    });

    return { 
      attendanceFilter,
      lojas,
      sendFilter,
      closeModal,
      ElConfigProvider,
      ptBr,
      selectedConcessionarias,
      arrayStatus,
      storeFilter,
      arrayUsers,
      arrayCategory,
      route
    };
  },
});
